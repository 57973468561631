import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { Box, Chip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { showNotification } from 'utils/showNotification'

import {
  useDeleteBookingListingMutation,
  useGetBookingListingByIdQuery,
} from 'services/bookings/api'

import ManageListingTable from 'components/bookings/listings/ManageListingTable/index'
import Button from 'components/common/Button'
import ConfirmModal from 'components/common/ConfirmModal'

import {
  CreateListingModal,
  TICKET_COLOR_MAP,
  TICKET_FORM_MAP,
  TicketFormResponseType,
} from '../CreateListingModal'
import { RegisterOnSaleModal } from '../RegisterOnSaleModal'
import { RegisterSaleModal } from '../RegisterSaleModal'
import { EventDetailsSection } from './EventDetailsSection'
import { OnSaleTable } from './OnSaleTable'
import { PriceSection } from './PriceSection'
import { TicketsTable } from './TicketsTable'
import { UserInfoSection } from './UserInfoSection'

export const SALE_STATUS_MAP = {
  0: { text: 'NotSold', color: 'error' },
  5: { text: 'OnSale', color: 'warning' },
  10: { text: 'Sold', color: 'secondary' },
  20: { text: 'Hold', color: 'success' },
  30: { text: 'PartiallySold', color: 'primary' },
  40: { text: 'Archived', color: 'info' },
  50: { text: 'Unfilled', color: 'warning' },
} as const

export type TModal =
  | 'REGISTER_SALE'
  | 'REGISTER_ON_SALE'
  | 'EDIT_ON_SALE'
  | 'DELETE_ON_SALE'
  | 'EDIT_LISTING'
  | 'DELETE_LISTING'
  | ''

export const ManageListing = () => {
  const { id } = useParams()
  const { data: listing, isFetching } = useGetBookingListingByIdQuery({ id: +id! })

  const navigate = useNavigate()
  const [modal, setModal] = useState<TModal>('')

  const handleOpenRegisterSaleModal = () => {
    setModal('REGISTER_SALE')
  }
  const handleOpenRegisterOnSaleModal = () => {
    setModal('REGISTER_ON_SALE')
  }

  const [deleteListing] = useDeleteBookingListingMutation()

  const handleDeleteListing = async () => {
    try {
      await deleteListing({
        listingIds: [+id!],
      }).unwrap()

      showNotification('Listing deleted', 'success')
      navigate(-1)
    } catch (e) {
      showNotification('Error. Try again later', 'error')
    }
  }

  return (
    <>
      <Box borderTop={1} borderColor="divider">
        <Typography fontWeight={800} fontSize="16px" py={2}>
          Manage Listing
        </Typography>
        <Box sx={{ width: '100%', padding: '24px 0' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography fontWeight={600} fontSize="16px">
                  Listing #{listing?.data?.id}
                </Typography>
                <Chip
                  label={
                    (listing?.data?.saleStatus || listing?.data?.saleStatus === 0) &&
                    SALE_STATUS_MAP[listing.data.saleStatus].text
                  }
                  color={
                    ((listing?.data?.saleStatus || listing?.data?.saleStatus === 0) &&
                      SALE_STATUS_MAP[listing.data.saleStatus].color) ||
                    'info'
                  }
                />
                <Chip
                  label={
                    (listing?.data?.ticketForm || listing?.data?.ticketForm === 0) &&
                    TICKET_FORM_MAP[listing?.data?.ticketForm as TicketFormResponseType]
                  }
                  color={TICKET_COLOR_MAP[listing?.data?.ticketForm as TicketFormResponseType]}
                />
                <IconButton onClick={() => setModal('EDIT_LISTING')} color="primary">
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => setModal('DELETE_LISTING')} color="warning">
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button onClick={handleOpenRegisterSaleModal} disabled={!listing}>
                  Register Sale
                </Button>
                <Button onClick={handleOpenRegisterOnSaleModal} disabled={!listing}>
                  Register On Sale
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <Typography fontWeight={600} fontSize="16px" py={1} component="span">
                {listing?.data?.eventCategory.eventName}
              </Typography>{' '}
              <Typography fontWeight={400} fontSize="14px" py={1} component="span">
                {listing?.data?.numberOfTickets} tickets, {listing?.data?.eventCategory.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', gap: 3, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '33%',
                  }}
                >
                  <TicketsTable tickets={listing?.data.tickets || []} isFetching={isFetching} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '66%',
                    height: '100%',
                  }}
                >
                  <OnSaleTable
                    modal={modal}
                    setModal={setModal}
                    sales={listing?.data.sales || []}
                    isFetching={isFetching}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  width: '100%',
                }}
              >
                {listing && <PriceSection listing={listing} />}
                {listing && <EventDetailsSection listing={listing} />}
                {listing && <UserInfoSection listing={listing} />}
              </Box>
            </Box>
          </Box>
          {listing?.data?.id && (
            <Box sx={{ border: '1px solid #E9E9EA', borderRadius: '8px', p: 2, mt: 3 }}>
              <Typography
                fontWeight={600}
                fontSize="16px"
                component="h4"
                width="100%"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                <ConfirmationNumberIcon sx={{ color: '#007BFF' }} />
                Sold
              </Typography>
              <ManageListingTable listingId={listing?.data?.id} />
            </Box>
          )}
        </Box>
      </Box>
      {modal === 'REGISTER_SALE' && (
        <RegisterSaleModal onClose={() => setModal('')} listing={listing!.data!} />
      )}
      {modal === 'REGISTER_ON_SALE' && (
        <RegisterOnSaleModal onClose={() => setModal('')} listing={listing!.data!} />
      )}
      {modal === 'EDIT_LISTING' && (
        <CreateListingModal onClose={() => setModal('')} listing={listing?.data} />
      )}
      {modal === 'DELETE_LISTING' && (
        <ConfirmModal onClose={() => setModal('')} onConfirm={handleDeleteListing} open={true}>
          Are you sure you want to delete listing with ID {id}?
        </ConfirmModal>
      )}
    </>
  )
}
