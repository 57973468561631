import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from 'theme/index'

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Badge, Chip, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { DataGridProProps } from '@mui/x-data-grid-pro'

import {
  ALL_NUMBER_OPERATORS,
  ALL_DATE_OPERATORS,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useActions } from 'hooks/useActions'
import { useExportReport } from 'hooks/useExportReport'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetBookingsListingQuery, useGetListingReportMutation } from 'services/bookings/api'
import { SALE_STATUSES, Ticket } from 'services/bookings/types'

import InnerListingTable from 'components/bookings/listings/InnerListingTable/index'
import BookingListingsTableToolbar from 'components/bookings/listings/ListingsTableToolbar'
import { EditTagsModal } from 'components/bookings/listings/tags/EditTagsModal'
import ConfirmExportModal from 'components/common/ConfirmExportModal/index'
import CustomDataGrid from 'components/table/DataGrid'

import Tags from './Tags'
import Tickets from './Tickets'

type Sales = {
  [key: string]: {
    text: string
    color: 'error' | 'success' | 'secondary' | 'warning' | 'primary' | 'info'
  }
}

export const SALE_STATUS_STYLE: Sales = {
  0: { text: 'NotSold', color: 'error' },
  5: { text: 'OnSale', color: 'warning' },
  10: { text: 'Sold', color: 'secondary' },
  20: { text: 'Hold', color: 'success' },
  30: { text: 'PartiallySold', color: 'primary' },
  40: { text: 'Archived', color: 'info' },
  50: { text: 'Unfilled', color: 'warning' },
}
export type BookingListingsTabActions =
  | 'ADD'
  | 'CLONE'
  | 'EDIT'
  | 'CONFIRM'
  | 'TAG'
  | 'EDIT_SINGLE'
  | 'CONFIRM_SINGLE'
  | 'EDIT_CARD'
  | 'EDIT_NOTE'
  | 'EDIT_TAGS'
  | 'CLONE_SINGLE'
  | ''
const BookingListingsTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'tags.id',
        headerName: 'Tags',
        flex: 1,
        align: 'center',
        hideable: false,
        sortable: false,
        type: 'singleSelect',
        filterOperators: [
          createAutocompleteOperator('/api/utility/tags/distinct', 'caption', 'id'),
          createNotEqualsAutocompleteOperator('/api/utility/tags/distinct', 'caption', 'id'),
        ],
        renderCell: ({ row }) => {
          return (
            <Box display="flex" alignItems="center">
              {row.tags.length ? (
                <Tags
                  tags={row.tags}
                  openEditTags={() => {
                    setRowForTagsEdit(row)
                    setModal('EDIT_TAGS')
                  }}
                />
              ) : (
                <ModeEditOutlineOutlinedIcon
                  onClick={(event) => {
                    event.stopPropagation()
                    setRowForTagsEdit(row)
                    setModal('EDIT_TAGS')
                  }}
                  sx={{ cursor: 'pointer' }}
                />
              )}
            </Box>
          )
        },
      },
      {
        field: 'id',
        headerName: 'Booking ID',
        type: 'Number',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        hideable: false,
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'orderedDateCet',
        headerName: 'Order Date (CET)',
        type: 'Datetime',
        flex: 2,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'eventCategory.event.name',
        headerName: 'Event',
        type: 'String',
        flex: 1.6,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory.eventName,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/listings/distinct', 'eventCategory.event.name'),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/listings/distinct',
            'eventCategory.event.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'eventCategory.event.event_group.name',
        headerName: 'Event group name',
        type: 'String',
        flex: 1.6,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory.eventGroupName,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/listings/distinct',
            'eventCategory.event.event_group.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/listings/distinct',
            'eventCategory.event.event_group.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'eventCategory.name',
        headerName: 'Category',
        type: 'String',
        flex: 1.5,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory.name,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/listings/distinct', 'eventCategory.name'),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/listings/distinct',
            'eventCategory.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'saleStatusHp',
        headerName: 'Sale Status',
        flex: 1.6,
        headerAlign: 'center',
        align: 'center',
        hideable: false,
        type: 'singleSelect',
        valueOptions: SALE_STATUSES,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => (
          <Chip
            label={SALE_STATUS_STYLE[row.saleStatus].text}
            color={SALE_STATUS_STYLE[row.saleStatus].color}
          />
        ),
      },
      {
        field: 'priceEur',
        headerName: 'Ticket Price, EUR',
        type: 'Number',
        flex: 1.7,
        align: 'center',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'totalPriceEurHp',
        headerName: 'Total Price, EUR',
        type: 'Number',
        flex: 1.7,
        align: 'center',
        valueGetter: ({ row }) => row?.priceEur * row?.tickets.length,
        filterable: false,
      },
      {
        field: 'user',
        headerName: 'User',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        filterOperators: [
          createAutocompleteOperator('/api/bookings/listings/distinct', 'user'),
          createNotEqualsAutocompleteOperator('/api/bookings/listings/distinct', 'user'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'password',
        headerName: 'Password',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        filterable: false,
      },
      {
        field: 'tickets',
        headerName: 'Tickets',
        type: 'Number',
        flex: 1.6,
        hideable: false,
        align: 'left',
        sortable: false,
        valueGetter: ({ row }) => row?.tickets.length,
        filterable: false,
        renderCell: ({ row }) => (
          <Tooltip
            placement="right"
            title={
              row.tickets.length
                ? row.tickets.map((item: Ticket) => <Tickets key={item.id} ticket={item} />)
                : 'There is no any added tickets.'
            }
          >
            <Box>
              <Badge
                badgeContent={row?.tickets.length}
                color="secondary"
                sx={{
                  '& .MuiBadge-badge': {
                    right: -3,
                    top: 18,
                    border: `2px solid ${theme.palette.background.default}`,
                    padding: '0 4px',
                  },
                }}
              >
                <ConfirmationNumberIcon
                  sx={{ color: '#007BFF', cursor: 'pointer' }}
                  fill="#007BFF"
                />
              </Badge>
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 2,
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              sx={{ marginRight: '10px' }}
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label=""
              icon={
                <Tooltip title="Edit Listing" placement="top">
                  <ModeEditOutlineOutlinedIcon />
                </Tooltip>
              }
            />,
            <GridActionsCellItem
              sx={{ marginRight: '10px' }}
              key={params.id}
              onClick={() => {
                setModal('CLONE_SINGLE')
                setSingleRow(params.row)
              }}
              label=""
              icon={
                <Tooltip title="Clone Listing" placement="top">
                  <CopyAllIcon />
                </Tooltip>
              }
            />,
            <GridActionsCellItem
              sx={{ marginRight: '10px' }}
              key={params.id}
              onClick={() => {
                navigate(`manage/${params.row.id}`)
              }}
              label=""
              icon={
                <Tooltip title="Manage Listing" placement="top">
                  <VisibilityIcon />
                </Tooltip>
              }
            />,
            <GridActionsCellItem
              sx={{ marginRight: '10px' }}
              key={params.id}
              onClick={() => {
                setModal('CONFIRM_SINGLE')
                setSingleRow(params.row)
              }}
              label=""
              icon={
                params.row.saleStatus === 40 ? (
                  <Tooltip title="From Archive" placement="top">
                    <RestoreFromTrashIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="To Archive" placement="top">
                    <DeleteIcon />
                  </Tooltip>
                )
              }
            />,
          ]
        },
      },
    ],
    []
  )
  const navigate = useNavigate()
  const [getReport, { isLoading: isDownload }] = useGetListingReportMutation()
  const [rowForTagsForEdit, setRowForTagsEdit] = useState<any>()
  const { modal, setModal, singleRow, setSingleRow } = useActions<BookingListingsTabActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, setSelectedRows, handleRowSelection } = useSelectedRows()
  const { isModal, setIsModal, handleExport, handleExportButtonClick } = useExportReport({
    queryParams,
    columns,
    getReport,
    reportName: 'Bookings_listings',
  })
  const { data, isFetching } = useGetBookingsListingQuery(
    { ...queryParams, showArchived: false },
    { refetchOnMountOrArgChange: true }
  )
  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <InnerListingTable price={row?.priceEur} listingId={row?.id} />, [])
  const getDetailPanelHeight = React.useCallback(() => 400, [])
  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<BookingListingsTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        onRowSelectionModelChange={handleRowSelection}
        onExportButtonClick={handleExportButtonClick}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={BookingListingsTableToolbar}
        checkboxSelection
        rowHeight={80}
        {...paginationDataGridProps}
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
      {modal === 'EDIT_TAGS' && (
        <EditTagsModal
          onClose={() => {
            setModal('')
            setSelectedRows([])
          }}
          item={rowForTagsForEdit}
        />
      )}
      {isModal && (
        <ConfirmExportModal
          open={true}
          onConfirm={handleExport}
          loading={isDownload}
          onClose={() => {
            setIsModal(false)
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Do you want to export only the current page or all the data?
          </Typography>
        </ConfirmExportModal>
      )}
    </Box>
  )
}

export default BookingListingsTab
