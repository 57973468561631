import React, { useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import Box from '@mui/material/Box/Box'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import Typography from '@mui/material/Typography/Typography'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro'
import { GridActionsCellItem } from '@mui/x-data-grid/components/cell/GridActionsCellItem'
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

import { useSelectedRows } from 'hooks/useSelectedRows'

import { useDeleteSaleMutation } from 'services/bookings/api'
import { ListingSale } from 'services/bookings/types'

import Button from 'components/common/Button'
import ConfirmModal from 'components/common/ConfirmModal'

import { TModal } from '..'
import { EditSaleModal } from '../../EditSaleModal/EditSaleModal'
import { SPLITING_TYPE_OPTIONS } from '../../RegisterOnSaleModal'

interface Props {
  modal: TModal
  setModal: React.Dispatch<React.SetStateAction<TModal>>
  sales: ListingSale[]
  isFetching: boolean
}

export const OnSaleTable = ({ modal, setModal, sales, isFetching }: Props) => {
  const [deleteSales] = useDeleteSaleMutation()
  const [singleRow, setSingleRow] = useState<ListingSale>()

  const { selectedRows, handleRowSelection } = useSelectedRows()

  const handleDeleteOnSale = async (selectedRows: any) => {
    await deleteSales({
      saleIds: selectedRows,
    })
    setModal('')
  }

  const salesColumns: GridColDef<ListingSale>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
    },
    {
      field: 'platformListingId',
      headerName: 'Platform Listing ID',
      flex: 1.2,
    },
    {
      field: 'numberOfTickets',
      headerName: 'Tickets',
      flex: 0.5,
    },
    {
      field: 'salePrice',
      headerName: 'Sale Price, EUR',
      flex: 0.8,
    },
    {
      field: 'splitType',
      headerName: 'Split Type',
      flex: 0.7,
      valueGetter: ({ row }) =>
        SPLITING_TYPE_OPTIONS.find((opt) => opt.value === row.splitType.toString())?.name,
    },
    {
      field: 'salePlatform',
      headerName: 'Sale platform',
      flex: 0.8,
    },
    {
      field: 'saleUser',
      headerName: 'Sale user',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0.5,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            sx={{ marginRight: '10px' }}
            key={params.id}
            onClick={() => {
              setModal('EDIT_ON_SALE')
              setSingleRow(params.row)
            }}
            label=""
            icon={
              <Tooltip title="Edit On Sale" placement="top">
                <ModeEditOutlineOutlinedIcon />
              </Tooltip>
            }
          />,
        ]
      },
    },
  ]

  return (
    <>
      <Box
        sx={{
          p: 2,
          border: '1px solid #E9E9EA',
          borderRadius: '8px',
          minHeight: '420px',
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Typography
          fontWeight={600}
          fontSize="16px"
          component="h4"
          width="100%"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <ContentCopyIcon sx={{ color: '#007BFF' }} />
            On Sale{' '}
          </Box>
          <Box>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => setModal('DELETE_ON_SALE')}
              disabled={selectedRows.length === 0}
            >
              <DeleteOutlineOutlinedIcon fill="#fff" />
            </Button>
          </Box>
        </Typography>
        <DataGridPro
          rows={sales}
          columns={salesColumns}
          loading={isFetching}
          pagination={false}
          slots={{ footer: () => null }}
          rowSelection
          onRowSelectionModelChange={handleRowSelection}
          checkboxSelection
        />
      </Box>
      {modal === 'DELETE_ON_SALE' && (
        <ConfirmModal
          onClose={() => setModal('')}
          onConfirm={() => handleDeleteOnSale(selectedRows)}
          open={true}
        >
          Are you sure you want to delete the sale
          {selectedRows.length > 1 ? 's' : ''} with ID
          {selectedRows.length > 1 ? 's' : ''} {selectedRows.join(', ')}?
        </ConfirmModal>
      )}
      {modal === 'EDIT_ON_SALE' && <EditSaleModal onClose={() => setModal('')} sale={singleRow} />}
    </>
  )
}
