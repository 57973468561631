import { json } from '@codemirror/lang-json'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, FC, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography'

import { showNotification } from 'utils/showNotification'

import { useEditBotStationMutation } from 'services/dashboard/api'
import { BotStation, BROWSER_MODE_OPTIONS } from 'services/dashboard/types'
import { EventGroupTransform } from 'services/events/types'

import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import CustomCheckbox from 'components/common/Checkbox/index'
import { CodeEditor } from 'components/common/CodeEditor/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import CustomSwitch from 'components/common/Switch'
import TextInput from 'components/common/TextInput/index'
import { EDIT_BOT_STATION } from 'components/dashboard/bot-station/EditBotStationModal/validation'

interface FormValues {
  eventGroupId: {
    value: string
    name: string
  }
  browser: {
    value: string
    name: string
  }
  logicFunctionNumber: number
  proxyTags: (string | undefined)[]
  mockOptions: {
    name: string
    enabled: boolean
  }[]
  onlyUnregisteredBots: boolean
  browserConfig: any
  extraOptions: any
  runnerConfig: any
}

interface Props {
  onClose: () => void
  botStation: BotStation
  eventGroups?: EventGroupTransform[]
}

export const EditBotStationModal: FC<Props> = ({ botStation, onClose, eventGroups }) => {
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [editBotStation, { isLoading }] = useEditBotStationMutation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(EDIT_BOT_STATION),
    defaultValues: {
      eventGroupId: {
        value: botStation?.eventGroupDetails?.id?.toString() || '',
        name: botStation?.eventGroupDetails?.name || '',
      },
      browser: {
        value: botStation?.browserMode.toString() || '',
        name:
          BROWSER_MODE_OPTIONS.find((v) => v.value === botStation?.browserMode.toString())?.name ||
          '',
      },
      logicFunctionNumber: botStation?.logicFunctionNumber || 0,
      proxyTags: botStation?.proxyMasks || [],
      mockOptions: botStation?.mockOptions || [],
      browserConfig: JSON.stringify(botStation?.browserArgs || {}, null, 2),
      extraOptions: JSON.stringify(botStation?.extraOptions || {}, null, 2),
      runnerConfig: JSON.stringify(botStation?.runnerConfig || {}, null, 2),
      onlyUnregisteredBots: botStation?.onlyUnregisteredBots || false,
    },
  })
  const { fields: mockOptions, replace } = useFieldArray({ control, name: 'mockOptions' })
  const onSubmit = async (editedBotStation: FormValues) => {
    //if (!jsonError) {
    editBotStation({
      ...editedBotStation,
      id: botStation.id,

      eventGroupId: +editedBotStation.eventGroupId.value,
      browser: +editedBotStation.browser.value,
      browserConfig: JSON.parse(editedBotStation.browserConfig),
      extraOptions: JSON.parse(editedBotStation.extraOptions),
      runnerConfig: JSON.parse(editedBotStation.runnerConfig),
    })
      .unwrap()
      .then(() => {
        showNotification('Bot Station  edited', 'success')
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
    //}
  }
  const handleChangeProxyTag = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: any
  ) => {
    setValue(name, e.target.value.split(','))
  }
  const handleJsonSet = (event: any, name: 'browserConfig' | 'extraOptions' | 'runnerConfig') => {
    setValue(name, event)
  }

  const onCheckSelectAll = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsAllSelected(checked)
    replace(mockOptions.map((option) => ({ ...option, enabled: checked })))
  }

  return (
    <AppModal title={`Edit Station ${botStation.ip}`} onClose={onClose}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: '700px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        >
          <ControlledSelectAutocomplete
            control={control}
            name="eventGroupId"
            label="Event Group"
            errors={errors}
            sx={{ display: 'flex', flex: 1, mb: 3 }}
            selectOptions={eventGroups}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <ControlledSelectAutocomplete
              control={control}
              name="browser"
              label="Browser"
              errors={errors}
              sx={{ display: 'flex', flex: 1 }}
              selectOptions={BROWSER_MODE_OPTIONS}
            />
            <TextInput
              control={control}
              name="logicFunctionNumber"
              label="Logic Function Number"
              errors={errors}
              sx={{ display: 'flex', flex: 1 }}
            />
          </Box>
          <TextInput
            name="proxyTags"
            label="Proxy Masks (separated by comma)"
            errors={errors}
            control={control}
            onChangeHandler={(e) => handleChangeProxyTag(e, 'proxyTags')}
          />
          <CustomSwitch
            control={control}
            name="onlyUnregisteredBots"
            disabledLabel="All accounts"
            enabledLabel="Unregistered accounts"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
            <Typography sx={{ textAlign: 'center' }}>Mock Options</Typography>
            <FormControlLabel
              label="Select/deselect all"
              control={
                <Checkbox
                  name="selectAll"
                  value={isAllSelected}
                  onChange={onCheckSelectAll}
                  sx={{
                    overflow: 'hidden',
                  }}
                />
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Grid container spacing={3}>
              {mockOptions.map((option, index) => (
                <Grid key={option.id} item xs={4}>
                  <CustomCheckbox
                    control={control}
                    name={`mockOptions.${index}.enabled`}
                    label={option.name}
                    sx={{
                      overflow: 'hidden',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <CodeEditor
            control={control}
            label="Browser Config"
            name="browserConfig"
            extentions={[json()]}
            onChange={(e: any) => handleJsonSet(e, 'browserConfig')}
            errors={errors}
          />
          <CodeEditor
            control={control}
            label="Extra Options"
            name="extraOptions"
            extentions={[json()]}
            onChange={(e: any) => handleJsonSet(e, 'extraOptions')}
            errors={errors}
          />
          <CodeEditor
            control={control}
            label="Runner Config"
            name="runnerConfig"
            extentions={[json()]}
            onChange={(e: any) => handleJsonSet(e, 'runnerConfig')}
            errors={errors}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading} fullWidth={isMobileOnly}>
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
