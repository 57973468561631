import React from 'react'

import EventIcon from '@mui/icons-material/Event'
import Box from '@mui/material/Box/Box'
import Divider from '@mui/material/Divider/Divider'
import Typography from '@mui/material/Typography/Typography'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { BookingsListing } from 'services/bookings/types'

interface Props {
  listing: { data: BookingsListing }
}

export const EventDetailsSection = ({ listing }: Props) => {
  return (
    <Box sx={{ p: 2, border: '1px solid #E9E9EA', borderRadius: '8px', flex: 1 }}>
      <Typography
        fontWeight={600}
        fontSize="16px"
        component="h4"
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <EventIcon sx={{ color: '#007BFF' }} />
        Event Details
      </Typography>
      <Divider sx={{ pt: 1 }} />
      <Typography fontWeight={600} fontSize="14px" py={1} component="p">
        Ordered Date
      </Typography>
      <Typography fontWeight={400} fontSize="14px" component="p">
        {listing?.data?.orderedDateCet && formatToLocaleTimezone(listing?.data?.orderedDateCet)}
      </Typography>
      <Typography fontWeight={600} fontSize="14px" py={1} component="p">
        Event date
      </Typography>
      <Typography fontWeight={400} fontSize="14px" component="p">
        {listing?.data?.eventCategory.eventDate &&
          formatToLocaleTimezone(listing.data.eventCategory.eventDate)}
      </Typography>
    </Box>
  )
}
