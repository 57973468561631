import { FC, SyntheticEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { TabsProps } from '@mui/material/Tabs/Tabs'

export interface TabItem {
  id: string
  label: string
  mobileOnly?: boolean
}

interface Props extends TabsProps {
  tabs: TabItem[]
  basePath: string
}

const NavigationTabs: FC<Props> = ({ tabs, basePath, ...rest }) => {
  tabs.map((v) => {
    if (v.id === '') throw new Error("Do not use id with value '', it's used for dev purposes")
  })

  const navigate = useNavigate()
  const location = useLocation()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }

  return (
    <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
      <Tabs
        {...rest}
        value={location.pathname}
        onChange={handleChange}
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          zIndex: 10,
        }}
      >
        {[{ id: '', label: '' }, ...tabs]?.map(({ id, label }) =>
          id === '' ? (
            <Tab
              key={id}
              value={id ? `${basePath}/${id}` : basePath}
              label={label}
              sx={{
                zIndex: 10,
                width: '0',
                visibility: 'hidden',
              }}
            />
          ) : (
            <Tab
              key={id}
              value={id ? `${basePath}/${id}` : basePath}
              label={label}
              sx={{
                zIndex: 10,
                width: '150px',
              }}
            />
          )
        )}
      </Tabs>
    </Box>
  )
}

export default NavigationTabs
