import * as React from 'react'
import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { StackProps } from '@mui/material'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

interface Props extends StackProps {
  control: any
  name: string
  disabledLabel?: string
  enabledLabel?: string
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

const CustomSwitch: FC<Props> = ({ name, control,disabledLabel='Disabled',enabledLabel='Enabled', ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Stack {...rest} direction="row" spacing={1} alignItems="center">
          <Typography>{disabledLabel}</Typography>
          <AntSwitch
            checked={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.checked)
            }}
          />
          <Typography>{enabledLabel}</Typography>
        </Stack>
      )}
    />
  )
}
export default CustomSwitch
