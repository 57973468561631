import React from 'react'

import PersonIcon from '@mui/icons-material/Person'
import Box from '@mui/material/Box/Box'
import Divider from '@mui/material/Divider/Divider'
import Typography from '@mui/material/Typography/Typography'

import { BookingsListing } from 'services/bookings/types'

import { CopyToClipboard } from 'components/common/CopyToClipboard'

interface Props {
  listing: { data: BookingsListing }
}

export const UserInfoSection = ({ listing }: Props) => {
  return (
    <Box sx={{ p: 2, border: '1px solid #E9E9EA', borderRadius: '8px', flex: 1.5 }}>
      <Typography
        fontWeight={600}
        fontSize="16px"
        component="h4"
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <PersonIcon sx={{ color: '#007BFF' }} />
        User info
      </Typography>
      <Divider sx={{ pt: 1 }} />
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box>
          <Typography fontWeight={600} fontSize="14px" py={1} component="p">
            E-mail
          </Typography>
          <Box>
            {listing?.data?.user ? (
              <CopyToClipboard value={listing?.data?.user} field="E-mail" />
            ) : null}
          </Box>
          <Typography fontWeight={600} fontSize="14px" py={1} component="p">
            Password
          </Typography>
          <Box>
            {listing?.data?.password ? (
              <CopyToClipboard value={listing?.data?.password} field="Password" />
            ) : null}
          </Box>
          <Typography fontWeight={600} fontSize="14px" py={1} component="p">
            Address
          </Typography>
          <Typography fontWeight={400} fontSize="14px" component="p">
            {listing?.data?.address}
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight={600} fontSize="14px" py={1} component="p">
            Card
          </Typography>
          <Typography fontWeight={400} fontSize="14px" component="p">
            {listing?.data?.card?.num}
          </Typography>
          {listing?.data?.note && (
            <>
              <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                Note
              </Typography>
              <Typography fontWeight={400} fontSize="14px" component="p">
                {listing?.data?.note}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
