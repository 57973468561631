import React from 'react'

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import Box from '@mui/material/Box/Box'
import Chip from '@mui/material/Chip/Chip'
import Typography from '@mui/material/Typography/Typography'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro'
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

import { Ticket } from 'services/bookings/types'

import { SALE_STATUS_MAP } from '..'

interface Props {
  tickets: Ticket[]
  isFetching: boolean
}

export const TicketsTable = ({isFetching, tickets}: Props) => {
  const ticketsColumns: GridColDef<Ticket>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'section',
      headerName: 'Section',
      flex: 1,
    },
    {
      field: 'row',
      headerName: 'Row',
      flex: 1,
    },
    {
      field: 'seat',
      headerName: 'Seat',
      flex: 1,
    },
    {
      field: 'saleStatusType',
      headerName: 'Sale Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      renderCell: ({ row }) => (
        <Chip
          label={
            (row.saleStatusType || row.saleStatusType === 0) &&
            SALE_STATUS_MAP[row.saleStatusType].text
          }
          color={
            ((row.saleStatusType || row.saleStatusType === 0) &&
              SALE_STATUS_MAP[row.saleStatusType].color) ||
            'info'
          }
        />
      ),
    },
  ]

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid #E9E9EA',
        borderRadius: '8px',

        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',

        height: 'calc(100vh - 500px)',
        overflowY: 'scroll',
        gap: 2,
      }}
    >
      <Typography
        fontWeight={600}
        fontSize="16px"
        component="h4"
        width="100%"
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <ConfirmationNumberIcon sx={{ color: '#007BFF' }} />
        Tickets
      </Typography>
      <DataGridPro
        rows={tickets}
        columns={ticketsColumns}
        loading={isFetching}
        pagination={false}
        slots={{ footer: () => null }}
      />
    </Box>
  )
}
