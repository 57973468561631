import * as yup from 'yup'

import { numberTypeMessage, requiredMessage } from 'validation/index'

export const EDIT_BOT_STATION = yup.object().shape({
  eventGroupId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  browser: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  logicFunctionNumber: yup.number().typeError(numberTypeMessage).required(requiredMessage),
  onlyUnregisteredBots: yup.boolean().required(requiredMessage),
  proxyTags: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
  mockOptions: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().trim().required(requiredMessage),
        enabled: yup.boolean().required(requiredMessage),
      })
    )
    .required(requiredMessage),
  browserConfig: yup
    .mixed()
    .test('isValidJson', 'Invalid JSON', (value: any) => {
      try {
        JSON.parse(value)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    })
    .required(requiredMessage),
  extraOptions: yup
    .mixed()
    .test('isValidJson', 'Invalid JSON', (value: any) => {
      try {
        JSON.parse(value)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    })
    .required(requiredMessage),
  runnerConfig: yup
    .mixed()
    .test('isValidJson', 'Invalid JSON', (value: any) => {
      try {
        JSON.parse(value)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    })
    .required(requiredMessage),
})
