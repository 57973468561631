import React from 'react'

import SellIcon from '@mui/icons-material/Sell'
import Box from '@mui/material/Box/Box'
import Divider from '@mui/material/Divider/Divider'
import Typography from '@mui/material/Typography/Typography'

import { BookingsListing } from 'services/bookings/types'

interface Props {
  listing: { data: BookingsListing }
}

export const PriceSection = ({ listing }: Props) => {
  return (
    <Box
      sx={{
        flex: 1,
        p: 2,
        border: '1px solid #E9E9EA',
        borderRadius: '8px',
      }}
    >
      <Typography
        fontWeight={600}
        fontSize="16px"
        component="h4"
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        <SellIcon sx={{ color: '#007BFF' }} />
        Price
      </Typography>
      <Divider sx={{ pt: 1 }} />
      <Typography fontWeight={600} fontSize="14px" py={1} component="p">
        Price, EUR
      </Typography>
      <Typography fontWeight={400} fontSize="14px" component="p">
        {listing?.data?.priceEur}
      </Typography>
      <Typography fontWeight={600} fontSize="14px" py={1} component="p">
        Total price, EUR
      </Typography>
      <Typography fontWeight={400} fontSize="14px" component="p">
        {listing?.data?.tickets?.length && listing?.data?.priceEur * listing?.data?.tickets?.length}
      </Typography>
      <Typography fontWeight={600} fontSize="14px" py={1} component="p">
        Fee, EUR
      </Typography>
      <Typography fontWeight={400} fontSize="14px" component="p">
        {listing?.data?.feeEur}
      </Typography>
    </Box>
  )
}
