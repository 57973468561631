import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box/Box'

import { showNotification } from 'utils/showNotification'

import { useEditProxyMutation } from 'services/bots/api'
import { CountriesTransform } from 'services/bots/types'

import { EDIT_PROXIES_SCHEMA } from 'components/bots/proxies/EditProxyModal/validation'
import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput/index'

interface Props {
  onCLose: () => void
  proxy: any
  countries: CountriesTransform[] | undefined
}

interface FormValues {
  host: string
  port: string
  countryId: {
    value: string
    name: string
  }
  username: string | null
  password: string | null
  tags: string[]
  maxBotsCount: number
}

export const EditProxyModal: FC<Props> = ({ countries, proxy, onCLose }) => {
  const [editProxy, { isLoading: isCreateProxies }] = useEditProxyMutation()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(EDIT_PROXIES_SCHEMA),
    defaultValues: {
      host: proxy.host,
      port: proxy.port,
      countryId: {
        value: proxy.countryId?.toString() || '0',
        name: proxy?.countryName || 'Any',
      },
      username: proxy.username || '',
      password: proxy.password || '',
      tags: proxy.tags,
      maxBotsCount: proxy.maxBotsCount,
    },
  })
  const onSubmit = async (updatedProxy: FormValues) => {
    await editProxy({ ...updatedProxy, id: proxy.id, countryId: +updatedProxy?.countryId?.value })
      .unwrap()
      .then(() => {
        showNotification('Proxies successfully added', 'success')
        onCLose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  const handleChangeProxyTag = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: any
  ) => {
    setValue(name, e.target.value.split(','))
  }
  return (
    <AppModal title="Edit Proxy" onClose={onCLose}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: '750px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <TextInput
              control={control}
              name="host"
              label="Host"
              errors={errors}
              sx={{ flex: 3 }}
            />
            <TextInput
              control={control}
              name="port"
              label="Port"
              errors={errors}
              sx={{ flex: 1 }}
            />
          </Box>
          <TextInput control={control} name="username" label="Username" errors={errors} />
          <TextInput control={control} name="password" label="Password" errors={errors} />
          <TextInput
            control={control}
            name="maxBotsCount"
            label="Max Bots Count"
            errors={errors}
            type="number"
          />
          <ControlledSelectAutocomplete
            name={`countryId`}
            label="Country"
            errors={errors?.countryId?.message}
            control={control}
            selectOptions={countries}
          />
          <TextInput
            control={control}
            name="tags"
            label="Proxy Tags (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleChangeProxyTag(e, 'tags')}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onCLose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isCreateProxies}
            fullWidth={isMobileOnly}
          >
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
